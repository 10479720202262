import React from 'react';

import { DEFAULT_TRIAL_DAYS, isClientRendered } from './config';

export const checkEmail = (email) => {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};

export const getDateTextFromString = (text) => {
  const date = new Date(text);

  if (date.toString() === 'Invalid Date') {
    return text;
  }

  return getDateText(date);
};

export const getDateText = (date = new Date()) => {
  return date.toISOString().substr(0, 10);
};

export const getDateDayDiff = (date1, date2 = new Date()) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const getRelativeDateText = (date) => {
  const days = getDateDayDiff(date);

  if (days > 50) {
    const months = Math.round(days / 30);
    return `Inom ${months} månad${months > 1 ? 'er' : ''}`;
  }

  if (days > 15) {
    const weeks = Math.round(days / 7);
    return `Inom ${weeks} ${weeks > 1 ? 'veckor' : 'vecka'}`;
  }

  return `Inom ${days} dag${days > 1 ? 'ar' : ''}`;
};

export const clientUrl = (url, fallback = '') => (isClientRendered ? url : fallback);

export const clearCodeInfo = () => {
  localStorage.removeItem('codeInfo');
};
export const getCodeInfo = () => {
  const defaultCode = `campaign|day|${DEFAULT_TRIAL_DAYS}`;
  try {
    let [type, unit, duration] = (localStorage.getItem('codeInfo') || defaultCode).split('|');
    duration = +duration;

    return { type, unit, duration };
  } catch (_) {
    return { type: 'campaign', unit: 'day', duration: DEFAULT_TRIAL_DAYS };
  }
};
export const setCodeInfo = (type, unit, duration) => {
  localStorage.setItem('codeInfo', `${type}|${unit}|${duration}`);
};

function formatDuration(unit, duration) {
  let formattedUnit = '';

  switch (unit) {
    case 'year':
      formattedUnit = 'år';
      break;
    case 'month':
      formattedUnit = `månad${duration === 1 ? '' : 'er'}`;
      break;
    case 'week':
      formattedUnit = `veck${duration === 1 ? 'a' : 'or'}`;
      break;
    case 'day':
      if (duration > 14 && duration % 7 === 0) {
        return formatDuration('week', duration / 7);
      }
      formattedUnit = `dag${duration === 1 ? '' : 'ar'}`;
      break;
    default:
      throw new Error(`Vet tyvärr inte hur '${unit}' och '${duration}' ska formatteras :(`);
  }

  return {
    duration,
    formattedUnit,
    text: `${duration} ${formattedUnit}`,
  };
}

export const getTrialPeriod = ({ unit, duration } = getCodeInfo()) => {
  const { duration: dur, formattedUnit } = formatDuration(unit, duration);
  return (
    <>
      {dur}&nbsp;{formattedUnit}
    </>
  );
};

export const removeUtmTrackingInUrl = () => new Promise((res) => {
  setTimeout(() => {
    try {
      const url = new URL(window.location);
      const paramsToRemove = [];
      url.searchParams.forEach((value, key) => {
        if (key.startsWith('utm')) {
          paramsToRemove.push(key);
        }
      });
      console.debug('remove tracking:', paramsToRemove);
      paramsToRemove.forEach((key) => {
        url.searchParams.delete(key);
      });
      window.history.replaceState({}, '', url);
    } catch (_) { }
    return res();
    // Wait 1 sec for GA to track page load event
  }, 1000);
})

export const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
export const sleepRnd = (ms, MS) => sleep(Math.random() * (MS - ms) + ms);
