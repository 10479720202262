export const USER = Object.freeze({
  LOADED: 'USER.LOADED',

  LOGGED_IN: 'USER.LOGGED_IN',
  LOGGED_OUT: 'USER.LOGGED_OUT',

  DATA_LOADED: 'USER.DATA_LOADED',
  EMAIL_UPDATED: 'USER.EMAIL_UPDATED',
  PAYMENT_INFO_LOADED: 'PAYMENT_INFO_LOADED',
});
