// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-30-js": () => import("./../../../src/pages/30.js" /* webpackChunkName: "component---src-pages-30-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-fannyanderssonericsson-js": () => import("./../../../src/pages/fannyanderssonericsson.js" /* webpackChunkName: "component---src-pages-fannyanderssonericsson-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-favo-js": () => import("./../../../src/pages/favo.js" /* webpackChunkName: "component---src-pages-favo-js" */),
  "component---src-pages-getstarted-js": () => import("./../../../src/pages/getstarted.js" /* webpackChunkName: "component---src-pages-getstarted-js" */),
  "component---src-pages-giftcard-confirmation-js": () => import("./../../../src/pages/giftcard/confirmation.js" /* webpackChunkName: "component---src-pages-giftcard-confirmation-js" */),
  "component---src-pages-giftcard-index-js": () => import("./../../../src/pages/giftcard/index.js" /* webpackChunkName: "component---src-pages-giftcard-index-js" */),
  "component---src-pages-giftcard-purchase-js": () => import("./../../../src/pages/giftcard/purchase.js" /* webpackChunkName: "component---src-pages-giftcard-purchase-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-2020-11-product-marketing-intern-js": () => import("./../../../src/pages/jobs/2020-11-product-marketing-intern.js" /* webpackChunkName: "component---src-pages-jobs-2020-11-product-marketing-intern-js" */),
  "component---src-pages-jobs-2021-03-head-of-growth-js": () => import("./../../../src/pages/jobs/2021-03-head-of-growth.js" /* webpackChunkName: "component---src-pages-jobs-2021-03-head-of-growth-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-josefin-js": () => import("./../../../src/pages/josefin.js" /* webpackChunkName: "component---src-pages-josefin-js" */),
  "component---src-pages-josvigg-js": () => import("./../../../src/pages/josvigg.js" /* webpackChunkName: "component---src-pages-josvigg-js" */),
  "component---src-pages-kampanjer-komtillbaka-js": () => import("./../../../src/pages/kampanjer/komtillbaka.js" /* webpackChunkName: "component---src-pages-kampanjer-komtillbaka-js" */),
  "component---src-pages-kampanjer-sportlov-2021-js": () => import("./../../../src/pages/kampanjer/sportlov2021.js" /* webpackChunkName: "component---src-pages-kampanjer-sportlov-2021-js" */),
  "component---src-pages-kampanjer-sportlovslasning-js": () => import("./../../../src/pages/kampanjer/sportlovslasning.js" /* webpackChunkName: "component---src-pages-kampanjer-sportlovslasning-js" */),
  "component---src-pages-kampanjer-sportlovslsaning-js": () => import("./../../../src/pages/kampanjer/sportlovslsaning.js" /* webpackChunkName: "component---src-pages-kampanjer-sportlovslsaning-js" */),
  "component---src-pages-kampanjer-vankampanj-js": () => import("./../../../src/pages/kampanjer/vankampanj.js" /* webpackChunkName: "component---src-pages-kampanjer-vankampanj-js" */),
  "component---src-pages-kompis-js": () => import("./../../../src/pages/kompis.js" /* webpackChunkName: "component---src-pages-kompis-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-moa-js": () => import("./../../../src/pages/moa.js" /* webpackChunkName: "component---src-pages-moa-js" */),
  "component---src-pages-next-step-js": () => import("./../../../src/pages/next-step.js" /* webpackChunkName: "component---src-pages-next-step-js" */),
  "component---src-pages-pask-js": () => import("./../../../src/pages/pask.js" /* webpackChunkName: "component---src-pages-pask-js" */),
  "component---src-pages-password-js": () => import("./../../../src/pages/password.js" /* webpackChunkName: "component---src-pages-password-js" */),
  "component---src-pages-paw-js": () => import("./../../../src/pages/paw.js" /* webpackChunkName: "component---src-pages-paw-js" */),
  "component---src-pages-press-2021-01-21-telia-js": () => import("./../../../src/pages/press/2021-01-21-telia.js" /* webpackChunkName: "component---src-pages-press-2021-01-21-telia-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-redeem-js": () => import("./../../../src/pages/redeem.js" /* webpackChunkName: "component---src-pages-redeem-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-sova-js": () => import("./../../../src/pages/sova.js" /* webpackChunkName: "component---src-pages-sova-js" */),
  "component---src-pages-telia-js": () => import("./../../../src/pages/telia.js" /* webpackChunkName: "component---src-pages-telia-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

