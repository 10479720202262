export const ENV = process.env.ENVIRONMENT || process.env.NODE_ENV;

export const isProd = ENV === 'production';
export const isClientRendered = typeof window !== `undefined`;
export const DEFAULT_TRIAL_DAYS = 14;

export const API_HOST = process.env.GATSBY_API_HOST;
export const PAYMENT_API_HOST = process.env.GATSBY_PAYMENT_API_HOST;

export const STRIPE_PUBLISHABLE_KEY = process.env.GATSBY_STRIPE_PUBLISHABLE_KEY;
export const STRIPE_IS_TEST = STRIPE_PUBLISHABLE_KEY.startsWith('pk_test');

export const defaultGiftcardProducts = Object.freeze([
  {
    description: '3 månader',
    months: 3,
    price: 185,
    productId: '3months',
    shortDescription: '3 mån',
  },
  {
    description: '6 månader',
    months: 6,
    price: 349,
    productId: '6months',
    shortDescription: '6 mån',
  },
  {
    description: '12 månader',
    months: 12,
    popular: true,
    price: 659,
    productId: '1year',
    shortDescription: '12 mån',
  },
]);

export const FAQ_DATA = `Innehåll
Vilka böcker har ni?
Vi erbjuder över 1000 kvalitativa och kända böcker med karaktärer såsom Greta Gris, Pelle Svanslös och Mumintrollen. Det är möjligt genom våra samarbeten med i princip alla stora bokförlag som t.ex. Bonnier Carlsén och Natur & Kultur. 
För att ta reda på mer om vilka böcker som vi har så kan du också söka på författare, kategorier och titlar på vår hemsida: https://boksnok.se/books  

Vad innebär läs och lyssna samtidigt? 
Det innebär att man medan man följer illustrationerna i bilderböckerna får boken uppläst av en professionell berättarröst. På så sätt kan även de allra minsta ta del av barnböckernas magiska värld helt på egen hand.

Har ni böcker på andra språk?
Vi har i nuläget främst Svenska böcker. Men vi arbetar på att erbjuda fler språk. Skicka gärna ett mail till hej@boksnok.se om du har specifika önskemål på språk, eller tipsa oss på chatten på vår hemsida!


Funktionalitet
Vilka enheter kan jag använda Boksnok på?
Boksnok finns på iOS- och Android-enheter på både mobil- och surfplatta och stödjer upp till fyra år gamla iOS- och Android-enheter. 
Vi skulle tipsa att ni som har surfplatta testar att läsa där också, bilderböckerna är lite extra fina där :)

Har ni ljudböcker?
Vi fokuserar på att erbjuda kombinationen av ljud och bild där berättarrösten förstärker berättelsens illustrationer. Vi tror att det blir bäst så just nu, den kombinationen hjälper även små barn att ta del av fantastiska berättelser på egen hand. 
Vi erbjuder därför i nuläget inte ljudböcker, men det kan säkert komma att ändras i framtiden för att nå ut till ännu fler barn.

Kan jag streama till TV/Sonos?
Inte i dagsläget, men vi arbetar på att göra Boksnok tillgänglig för fler enheter.

Kan man använda Boksnok på dator?
Nej, inte i nuläget. Vi har börjat med att skapa en riktigt fin läsupplevelse på smartphone och surfplatta. Men det kommer komma någon gång i framtiden när vi kan ge en lika fin läsupplevelse på en dator.

Hur många samtidiga enheter kan jag ha?
Du kan ha två samtidiga enheter.

Hur sparar jag böcker för olika barn?
Det kan du göra genom att skapa Bokhyllor i iOS-appen, eller genom att favoritmarkera böckerna i iOS eller Android-appen. 
Under våren 2021 kommer även separata profiler per barn att lanseras som gör att man kan skräddarsy innehållet för varje barn.

Hur laddar jag ner böcker offline?
Det gör du genom att klicka på boken du vill ladda ner och trycka på nedladdningsikonen. Du hittar sedan nedladdade böcker under sektionen “nedladdningar” i appen.

Ljudet fungerar inte.
Majoriteten av böckerna i Boksnok är inlästa, men då kan det vara så att den bok du läser inte har ljud. De böcker som har ljud har en blå ljudikon på framsidan. 
Vi läser in alla våra böcker löpande, om det är en specifik titel ni önskar var inläst så maila oss på hej@boksnok.se så tittar vi på det!
Har boken en ljudikon? Kontrollera då att ljudet inte är avstängt:
\tI appen. Det kan du göra genom att pausa i boken och klicka på ljudikonen i högra hörnet.
\tPå din enhet. Om volymen är på noll så kommer ljudet inte att spelas upp.


Om tjänsten
Vad är Boksnok?
Boksnok är en prenumerationstjänst för barnböcker i form av en barnboksapp.
Boksnok erbjuder en trygg tjänst helt fri från reklam med över 1000 kvalitativa och välkända barnböcker såsom Greta Gris, Pelle Svanslös och Babblarna.
I Boksnok-appen kan du få boken uppläst av en berättarröst, ladda ner favoritboken offline eller läsa på egen hand. 

Varför finns Boksnok?
Vårt mål är att erbjuda kvalitativ underhållning på ett nytt roligt sätt genom vår tjänst för digitala barnböcker. På så sätt skapar vi meningsfull skärmtid och ökar läslusten bland barn. 

Vilken ålder passar tjänsten för?
Vi riktar oss främst mot barn upp till nio år.

Kan jag läsa hur mycket jag vill? 
Japp, du kan läsa precis hur mycket böcker du vill!

Vilka ligger bakom Boksnok?
Vi är fyra grundare bakom Boksnok som alla har ett starkt teknikintresse. Vi läste också alla mycket när vi var små så läsning ligger oss varmt om hjärtat. Boksnok är helt enkelt ett sätt för oss att använda vårt teknikintresse till en bra sak. Läs mer om oss: https://boksnok.se/about 


Om digital läsning
Vill ni ersätta vanlig läsning?
Nej, vi kommer aldrig ersätta fysisk läsning. Däremot ser vi Boksnok som ett naturligt komplement till fysisk läsning i de fall digital läsning passar bättre - såsom på resan, under bilfärden eller när man läst samma bok om och om, och om, och om, och om, och om igen...

Skärmtid är väl dåligt för barn?
Man kan enligt experter dela in skärmtid i tre typer - negativ skärmtid, positiv skärmtid och skärmtid som är varken positiv eller negativ. 
Genom att fokusera på läsning innebär Boksnok positiv skärmtid, eller som Boksnok kallar det - meningsfull skärmtid.
Boksnok handlar inte heller om att köpa mer skärmtid, det handlar om att byta ut den befintliga skärmtiden mot något mer meningsfullt - som dessutom skapar ökad läslust och hjälper till att hitta nya bokfavoriter.


Övrigt
Jag har glömt mitt lösenord - vad gör jag?
Då trycker du på knappen “glömt ditt lösenord” på inloggningsskärmen i appen och skriver i den mailadress som du använde när du skapade kontot. Du får då ett mail med instruktioner om hur du uppdaterar lösenordet.

Vart vänder jag mig om jag har fler frågor om tjänsten?
Då kan du skicka ett mail till hej@boksnok.se, skriva till oss på vår chatt på vår hemsida (https://boksnok.se) eller ringa till oss på de nummer som du hittar på https://boksnok.se/about .`;
